@import "@ebay/skin/dist/variables/variables.less";

:root {
  // Typography
  --font-sans: "Market Sans", "Arial", sans-serif;
  --base-line-height: 1.7;

  // Font sizes/themes from Evo
  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-body: 14px;
  --font-size-regular: unit((16px / @base-unit-px), rem);
  --font-size-md: unit((16px / @base-unit-px), rem);
  --font-size-lg-1: unit((20px / @base-unit-px), rem);
  --font-size-lg-2: unit((24px / @base-unit-px), rem);
  --font-size-giant-1: unit((30px / @base-unit-px), rem);
  --font-size-giant-2: unit((36px / @base-unit-px), rem);
  --font-size-giant-3: unit((46px / @base-unit-px), rem);

  // Colors
  --color-neutral-0: #fff;
  --color-neutral-1: #f7f7f7;
  --color-neutral-2: #e5e5e5;
  --color-neutral-3: #c7c7c7;
  --color-neutral-4: #8f8f8f;
  --color-neutral-5: #707070;
  --color-neutral-6: #363636;
  --color-neutral-7: #191919;
  --color-neutral-8: #000;
  --color-orange-1: #ffdec7;
  --color-orange-2: #feb786;
  --color-orange-3: #ff8559;
  --color-orange-4: #ff6932;
  --color-orange-5: #db3c07;
  --color-orange-6: #b03005;
  --color-orange-7: #5c1b05;
  --color-red-1: #ffd1dd;
  --color-red-2: #ffa2b6;
  --color-red-3: #ff6383;
  --color-red-4: #e0103a;
  --color-red-5: #c4003a;
  --color-red-6: #a00739;
  --color-red-7: #680226;
  --color-magenta-1: #fad8f0;
  --color-magenta-2: #f5a0d9;
  --color-magenta-3: #ea5fbc;
  --color-magenta-4: #d11da4;
  --color-magenta-5: #a60d8a;
  --color-magenta-6: #82187c;
  --color-magenta-7: #500750;
  --color-blue-1: #c5e5fb;
  --color-blue-2: #93c9ff;
  --color-blue-3: #659eff;
  --color-blue-4: #3665f3;
  --color-blue-5: #382aef;
  --color-blue-6: #2b0eaf;
  --color-blue-7: #121258;
  --color-green-1: #ccfdce;
  --color-green-2: #9ef4a6;
  --color-green-3: #5ee471;
  --color-green-4: #28a443;
  --color-green-5: #1bab49;
  --color-green-6: #05823f;
  --color-green-7: #07522c;
  --color-yellow-1: #fcf2bd;
  --color-yellow-2: #f7e376;
  --color-yellow-3: #fbcd25;
  --color-yellow-4: #f7b100;
  --color-yellow-5: #e58c02;
  --color-yellow-6: #aa5404;
  --color-yellow-7: #592e13;
  --color-teal-1: #c2f2ef;
  --color-teal-2: #71e3e2;
  --color-teal-3: #1dcbca;
  --color-teal-4: #02a2ac;
  --color-teal-5: #01718f;
  --color-teal-6: #0e4a6c;
  --color-teal-7: #003147;
  --color-lime-1: #f4fabe;
  --color-lime-2: #e9f577;
  --color-lime-3: #c9e43b;
  --color-lime-4: #accf02;
  --color-lime-5: #86b300;
  --color-lime-6: #4b7d06;
  --color-lime-7: #364f03;

  // Color aliases
  --color-state-focus: var(--color-state-accent-active, #93c9ff);

  // Spacings
  --space-unit: 8px;
  --space-25: calc(0.25 * var(--space-unit));
  --space-50: calc(0.5 * var(--space-unit));
  --space-100: calc(1 * var(--space-unit));
  --space-150: calc(1.5 * var(--space-unit));
  --space-200: calc(2 * var(--space-unit));
  --space-250: calc(2.5 * var(--space-unit));
  --space-300: calc(3 * var(--space-unit));
  --space-350: calc(3.5 * var(--space-unit));
  --space-400: calc(4 * var(--space-unit));
  --space-450: calc(4.5 * var(--space-unit));
  --space-500: calc(5 * var(--space-unit));
  --space-550: calc(5.5 * var(--space-unit));
  --space-600: calc(6 * var(--space-unit));
  --space-650: calc(6.5 * var(--space-unit));
  --space-700: calc(7 * var(--space-unit));
  --space-750: calc(7.5 * var(--space-unit));
  --space-800: calc(8 * var(--space-unit));
  --space-850: calc(8.5 * var(--space-unit));
  --space-900: calc(9 * var(--space-unit));
  --space-950: calc(9.5 * var(--space-unit));
  --space-1000: calc(10 * var(--space-unit));
  --space-1200: calc(12 * var(--space-unit));
  --space-1400: calc(14 * var(--space-unit));
  --space-1600: calc(16 * var(--space-unit));

  // Radius
  --border-radius-25: 4px;
  --border-radius-50: 8px;
  --border-radius-75: 12px;
  --border-radius-100: 16px;
  --border-radius-150: 24px;
  --border-radius-200: 32px;
  --border-radius-sm: var(--border-radius-25);
  --border-radius-md: var(--border-radius-50);
  --border-radius-lg: var(--border-radius-100);
  --border-radius-xl: var(--border-radius-150);
  --border-radius-full: var(--border-radius-2002);
  --border-radius-pill: 999px;

  // Evo Icon Sizes
  --icon-size-xs: 12px;
  --icon-size-sm: 16px;
  --icon-size-md: 20px;
  --icon-size-lg: 24px;
  --icon-size-xl: 28px;

  // Evo Icon circle backgrounds sizes
  --icon-circle-size-sm: 32px;
  --icon-circle-size-md: 40px;
  --icon-circle-size-lg: 48px;
  --icon-circle-size-xl: 56px;
  --icon-close-size: var(--icon-circle-size-md);

  // Avatars
  --avatar-size-xs: 32px;
  --avatar-size-sm: 40px;
  --avatar-size-md: 48px;
  --avatar-size-lg: 56px;
  --avatar-size-xl: 72px;
  --avatar-size-xl2: 96px;
  --avatar-size-xl3: 150px;

  // Shadows
  --shadow-popover: 0 2px 8px 2px rgb(0 0 0 / 7%);
  --shadow-focus: 0px 0px 0px 2px rgb(0 99 217 / 30%), 0px 0px 2px 4px #0063d926;
  --shadow-subtle: 0 2px 4px 0px rgb(0 0 0 / 15%);

  // Easings
  --ease-1: cubic-bezier(0.4, 0, 0.2, 1);
  --ease-2: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-3: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-4: cubic-bezier(0.58, 0.3, 0.005, 1);
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
  --ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
}
